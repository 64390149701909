import React, { CSSProperties, useMemo } from 'react'
import { Routes, Route, Outlet, useLocation, Location } from 'react-router-dom'
import { ModalUnstyled } from '@mui/base'
import { ViewWrapper } from './components/ViewWrapper'
import { DialogProvider } from './dialogs'
import {
  StateProvider,
  createStore,
  asyncFlow,
} from '@the-platform-company/appbuilder-react-state'
import { RobotenDnDProvider } from '@the-platform-company/react-drag-drop'
import { useConnectionSecrets } from '@the-platform-company/appbuilder-react-connector'
const MasterView = React.lazy(
  () => import(/* webpackChunkName: "views/master" */ './views/Master')
)
const ModalsView = React.lazy(
  () => import(/* webpackChunkName: "views/modals" */ './views/Modals')
)
const MasterContactsView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/contacts" */ './views/MasterContacts')
)
const MasterHomeView = React.lazy(
  () => import(/* webpackChunkName: "views/home" */ './views/MasterHome')
)
const DialogsNewView = React.lazy(
  () => import(/* webpackChunkName: "views/new" */ './views/DialogsNew')
)
const DialogsContactContextMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/contact-context-menu" */ './views/DialogsContactContextMenu'
    )
)
const ModalsCreateContactView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/create-contact" */ './views/ModalsCreateContact'
    )
)
const ModalsCreateFolderView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/create-folder" */ './views/ModalsCreateFolder'
    )
)
const ContactsAuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/ContactsAuthCallback" */ './components/connectors/ContactsAuthCallback'
    )
)
const AccountAuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/AccountAuthCallback" */ './components/connectors/AccountAuthCallback'
    )
)
const store = createStore()

const modalStyles: CSSProperties = {
  position: 'fixed',
  zIndex: '1300',
  inset: '0px',
}
const App: React.FC = () => {
  const location = useLocation()

  const state = (location.state as { backgroundLocation?: Location }) ?? {}

  const connections = useConnectionSecrets({
    connectionNames: ['contacts', 'account'],
  })
  return (
    <>
      <StateProvider
        additionalState={{
          connections,
        }}
        store={store}
      >
        <RobotenDnDProvider>
          <DialogProvider>
            <>
              <React.Suspense fallback={null}>
                <Routes location={state?.backgroundLocation ?? location}>
                  <Route
                    path=""
                    element={
                      <ViewWrapper
                        namespace={'b42d08db-379f-4b11-af4b-26f52741208b'}
                      >
                        <MasterView />
                      </ViewWrapper>
                    }
                  >
                    <Route
                      path="/"
                      element={
                        <ViewWrapper
                          namespace={'c2ee295a-eaea-47a0-bfd4-50e25ebad303'}
                        >
                          <MasterHomeView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/contacts/:parentId"
                      element={
                        <ViewWrapper
                          namespace={'a113b898-5b9e-4055-91c2-e815ec721bfd'}
                        >
                          <MasterContactsView />
                        </ViewWrapper>
                      }
                    />
                  </Route>
                  <Route
                    path="/dialogs/new/:parentId"
                    element={
                      <ViewWrapper
                        namespace={'5a9ff51c-90a0-4149-9e98-1dc4eb847f4e'}
                      >
                        <DialogsNewView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/dialogs/contact-context-menu/:contactType/:contactId"
                    element={
                      <ViewWrapper
                        namespace={'78074314-48c6-42a1-86ce-617957025dd1'}
                      >
                        <DialogsContactContextMenuView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path=""
                    element={
                      <ViewWrapper
                        namespace={'bc9721c4-479a-4eb4-ab80-22a01cd28fbc'}
                      >
                        <ModalsView />
                      </ViewWrapper>
                    }
                  >
                    <Route
                      path="/create-contact/:parentId"
                      element={
                        <ViewWrapper
                          namespace={'6da4b390-b22b-47e8-bf7e-86c8b3a646f8'}
                        >
                          <ModalsCreateContactView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/create-folder/:parentId"
                      element={
                        <ViewWrapper
                          namespace={'5a74959c-13e6-422b-8674-c22fcfc4b8f1'}
                        >
                          <ModalsCreateFolderView />
                        </ViewWrapper>
                      }
                    />
                  </Route>

                  <Route
                    path="/auth/contacts/callback"
                    element={<ContactsAuthCallback />}
                  />

                  <Route
                    path="/auth/account/callback"
                    element={<AccountAuthCallback />}
                  />
                </Routes>

                {/* Show the modal when a `backgroundLocation` is set */}
                {state?.backgroundLocation && (
                  <Routes>
                    <Route
                      path=""
                      element={
                        <ModalUnstyled
                          open={!!state?.backgroundLocation}
                          style={modalStyles}
                        >
                          <div>
                            <Outlet />
                          </div>
                        </ModalUnstyled>
                      }
                    >
                      <Route
                        path=""
                        element={
                          <ViewWrapper
                            namespace={'b42d08db-379f-4b11-af4b-26f52741208b'}
                          >
                            <MasterView />
                          </ViewWrapper>
                        }
                      >
                        <Route
                          path="/"
                          element={
                            <ViewWrapper
                              namespace={'c2ee295a-eaea-47a0-bfd4-50e25ebad303'}
                            >
                              <MasterHomeView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/contacts/:parentId"
                          element={
                            <ViewWrapper
                              namespace={'a113b898-5b9e-4055-91c2-e815ec721bfd'}
                            >
                              <MasterContactsView />
                            </ViewWrapper>
                          }
                        />
                      </Route>
                      <Route
                        path="/dialogs/new/:parentId"
                        element={
                          <ViewWrapper
                            namespace={'5a9ff51c-90a0-4149-9e98-1dc4eb847f4e'}
                          >
                            <DialogsNewView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/dialogs/contact-context-menu/:contactType/:contactId"
                        element={
                          <ViewWrapper
                            namespace={'78074314-48c6-42a1-86ce-617957025dd1'}
                          >
                            <DialogsContactContextMenuView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path=""
                        element={
                          <ViewWrapper
                            namespace={'bc9721c4-479a-4eb4-ab80-22a01cd28fbc'}
                          >
                            <ModalsView />
                          </ViewWrapper>
                        }
                      >
                        <Route
                          path="/create-contact/:parentId"
                          element={
                            <ViewWrapper
                              namespace={'6da4b390-b22b-47e8-bf7e-86c8b3a646f8'}
                            >
                              <ModalsCreateContactView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/create-folder/:parentId"
                          element={
                            <ViewWrapper
                              namespace={'5a74959c-13e6-422b-8674-c22fcfc4b8f1'}
                            >
                              <ModalsCreateFolderView />
                            </ViewWrapper>
                          }
                        />
                      </Route>
                    </Route>
                  </Routes>
                )}
              </React.Suspense>
            </>
          </DialogProvider>
        </RobotenDnDProvider>
      </StateProvider>
    </>
  )
}
export default App
